<template>
  <div class="maincontainer">
    <figure class="maincontainer__rio-logo">
      <img
        class="rio-logo"
        :src="require('../../assets/logos/logo.svg')"
        height="100px"
        width="320px"
      >
    </figure>
    <h4 class="maincontainer__header">
      {{ $t('Components.RedirectMobileUsers.mobile_device_prompt') }}!
    </h4>
    <p class="maincontainer__message-1">
      {{ $t('Components.RedirectMobileUsers.download_prompt') }}:
    </p>

    <!-- ANDROID DOWNLOAD BUTTON -->
    <div v-if="(system === 'android')">
      <a href="https://play.google.com/store/apps/details?id=com.riocompanion.app.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          class="maincontainer__download-button"
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        >
      </a>
    </div>

    <!-- iOS DOWNLOAD BUTTON -->
    <div v-else-if="(system === 'ios')">
      <a href="https://apps.apple.com/us/app/rio-companion/id1544707043?itsct=apps_box_badge&amp;itscg=30200">
        <img
          class="download-button"
          :src="require('../../assets/badges/Apple_app_store_badge_black.svg')"
          alt="Download on the App Store"
        >
      </a>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      system: '',
    };
  },
  created() {
    this.determineDevice();
  },
  methods: {
    determineDevice() {
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        this.checkLinks('https://apps.apple.com/us/app/rio-companion/id1544707043?itsct=apps_box_badge&amp;itscg=30200', 'ios');
      } else if (/Android|ANDROID|android/i.test(navigator.userAgent)) {
        this.checkLinks('https://play.google.com/store/apps/details?id=com.riocompanion.app.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', 'android');
      }
    },
    checkLinks(link, system) {
      try {
        window.open(link);
        this.system = system;
      } catch (error) {
        console.log('determineDevice error: ', error);
      }
    },
  },
};
</script>
